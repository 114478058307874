import React, { useEffect, useState } from "react";
import { Route, Redirect } from 'react-router-dom';

import './App.css';
import toplogo from '../src/assets/img/logo3.png';
import { send } from 'emailjs-com';

function App() {

  const [toSend, setToSend] = useState({
    from_name: '',
    to_name: '',
    mobile_number: '',
    reply_to: '',
    company: '',
    website: '',
    message: '',
  });

  const [name, setName] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    send(
      'service_unrzltq',
      'template_jfbf1ao',
      toSend,
      'iIVetmKSdaMmosFko'
    )
      .then((response) => {
        alert("Thank you very much for filling the form. We will get back to you soon!");
        window.location.reload();
        setToSend("");
        e.target.reset();
      })
      .catch((err) => {
        alert("Message Not Sent");
      });

    e.target.reset();
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div class="bgimg">
        <div class="middle">
          <img src={toplogo} className="img-fuild" />
          <h3 className="banner-text"><span className="underline"><span className="title_span">INTERLOCK RELATIONSHIPS TO UNLOCK VALUE</span></span></h3>
          <a className="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#portfolioModal1">Contact Us</a>
        </div>
        <div class="bottomleft">
          <h2>UNDER CONSTRUCTION BUT OPEN FOR BUSINESS</h2>
        </div>
      </div>
      <div class="portfolio-modal modal fade" id="portfolioModal1" tabindex="-1" aria-labelledby="portfolioModal1" aria-hidden="true">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header border-0"><h5 class="modal-title">CONTACT FORM</h5><button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                <div class="modal-body text-center pb-5">
                  <div class="container">

                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                          <form onSubmit={onSubmit} id='contactform'>

                            <div class="column">
                              <label>First Name *</label>
                              <input
                                type='text'
                                name='from_name'
                                class='form-control'
                                value={toSend.from_name}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div class="column">
                              <label>Last Name *</label>
                              <input
                                type='text'
                                name='to_name'
                                class='form-control'
                                value={toSend.to_name}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div class="column">
                              <label>Phone *</label>
                              <input
                                type='text'
                                name='mobile_number'
                                class='form-control'
                                value={toSend.mobile_number}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div class="column">
                              <label>Email *</label>
                              <input
                                type='email'
                                name='reply_to'
                                class='form-control'
                                value={toSend.reply_to}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div class="column">
                              <label>Company *</label>
                              <input
                                type='text'
                                name='company'
                                class='form-control'
                                value={toSend.company}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div class="column">
                              <label>Website *</label>
                              <input
                                type='text'
                                name='website'
                                class='form-control'
                                value={toSend.website}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div class="column1">
                              <label>Explain Your Project  *</label>
                              <textarea
                                name='message'
                                class='form-control text-area'
                                id="my-input"
                                maxlength="1500"
                                value={toSend.message}
                                onChange={handleChange}
                                required

                              />
                            </div>
                            <div class="column1">
                              <p id='remainingC'>1500 characters left</p>
                            </div>
                            <div class="column1 btn-footer text-right">
                              <button type='submit' class="btn banner-btn2">Submit</button>
                            </div>
                          </form>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

    </>
  );
}

export default App;
